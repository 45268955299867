import './App.css';
import Formulario from './Components/Formulario';

function App(){
    return(
        <div className='container'>
            <Formulario/>
        </div>
    )
}

export default App;