import React from 'react'

const Formulario = () => {
  return (
    <>
        <header>Registro</header>
        <form action='#' className='form'>
            <div className='input-box'>
                <label> Nombre Completo </label>
                <input type="text" name='nombre' id='nombre' placeholder='Ingresa tu nombre' required/>
            </div> 
            <div className='column'>
                <div className='input-box'>
                    <label>Puesto </label>
                    <input type="text" name='puesto' id='puesto' placeholder='Ingresa tu puesto' required/>
                </div>
                <div className='input-box'>
                    <label>Agencia </label>
                    <select name='agencia' id='agencia'>
                        <option value="value1">Opcion</option>
                        <option value="value2">Value 1</option>
                        <option value="value3">Value 2</option>
                        <option value="value4">Value 3</option>
                    </select>
                </div>
            </div>
            <div className='input-box'>
                <label> Nombre Usuario </label>
                <input type="text" name='nombre' id='nombre' placeholder='Ingresa tu nombre' required/>
            </div> 
            <div className='column'>
                <div className='input-box'>
                    <label>Puesto </label>
                    <input type="text" name='puesto' id='puesto' placeholder='Ingresa tu puesto' required/>
                </div>
                <div className='input-box'>
                    <label>Area </label>
                    <input type="text" name='area' id='area' placeholder='Ingresa tu area' required/>
                </div> 
            </div>
            <div className='procediment-box'>
                <div className='proc-option'>
                    <div className='proc'>
                        <input type="radio" id='check-alta'  name='check'/>
                        <label for = "check-alta">Alta</label>
                    </div>
                    <div className='proc'>
                        <input type="radio" id='check-baja'  name='check'/>
                        <label for = "check-baja">Baja</label>
                    </div>
                </div>
            </div>
            <div className='column'>
                <div className='input-box'>
                    <label>Correo</label>
                    <select name='correo' id='correo'>
                        <option value="value1">Opcion</option>
                        <option value="value2">SI</option>
                        <option value="value3">NO</option>
                    </select>
                </div>
            </div>
            <div className='column'>
                <div className='input-box'>
                    <label>HOB</label>
                    <select name='agencia' id='agencia'>
                        <option value="value1">Opcion</option>
                        <option value="value2">Value 1</option>
                        <option value="value3">Value 2</option>
                        <option value="value4">Value 3</option>
                    </select>
                </div>
                <div className='input-box'>
                    <label>DMS </label>
                    <select>
                        <option value="value1">Opcion</option>
                        <option value="value2">BUSINESS PRO</option>
                        <option value="value3">INTELISIS</option>
                        <option value="value2">W32</option>
                        <option value="value3">MAGIC</option>
                    </select>
                </div>
            </div>
            <div className='column'>
                <div className='input-box'>
                    <h3>Accesos Solicitados</h3><br/>
                    <textarea id="permisos" name="permisos" rows="7" cols="100"></textarea><br />
                </div>
            </div>
            <h3>Referencia de Accesos Usuario Activo</h3>
            <div className='input-box'>
                <label> Nombre Completo </label>
                <input type="text" name='nombre' id='nombre' placeholder='Ingresa tu nombre' required/>
            </div> 
            <div className='column'>
                <div className='input-box'>
                    <label>Area </label>
                    <input type="text" name='area' id='area' placeholder='Ingresa tu area' required/>
                </div> 
                <div className='input-box'>
                    <label>Puesto </label>
                    <input type="text" name='puesto' id='puesto' placeholder='Ingresa tu puesto' required/>
                </div>
            </div>
            <button>Enviar</button>
        </form>
    </>
  )
}

export default Formulario

